<template>
  <section class="login-block">
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="login-form-wrapper">
          <!-- Authentication card start -->
          <form class="md-float-material form-material" @submit.prevent="signIn">
            <div class="auth-box card">
              <div class="card-block">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-center txt-primary">{{ $t('user.enter') }}</h3>
                  </div>
                </div>

                <div class="form-group form-primary">
                  <label class="form-label">{{ $t('user.login') }}</label>
                  <input style="padding-left: 5px" type="text" name="user-name" class="form-control" v-model="login">
                  <span class="form-bar"></span>
                  <div v-if="isSubmited">
                    <div class="text-danger" v-if="!$v.login.required">{{ $t("user.login_required") }}</div>
                    <div class="text-danger" v-if="!$v.login.minLength">{{ $t("user.login_min_length") }}</div>
                  </div>
                </div>
                <div class="form-group form-primary" style="position: relative;">
                  <label class="form-label">{{ $t('user.password') }}</label>
                  <input style="padding-left: 5px" :type="showPass ? 'text' : 'password'" name="password"
                    class="form-control" v-model="password">
                  <i class="feather show-password" :class="{'icon-eye': !showPass, 'icon-eye-off': showPass}" @click="showPass = !showPass"></i>
                  <span class="form-bar"></span>
                  <div v-if="isSubmited">
                    <div class="text-danger" v-if="!$v.password.required">{{ $t("user.password_required") }}</div>
                    <div class="text-danger" v-if="!$v.password.minLength">
                      {{ $t("user.password_requirements") }}
                    </div>
                  </div>
                </div>
                <div class="row m-t-30">
                  <div class="col-md-12">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary btn-md waves-effect text-center m-b-20"
                        :disabled="isLoading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                          v-if="isLoading"></span>
                        <span v-else>{{ $t('user.enter') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="alert alert-danger" v-if="error" role="alert">
                  {{ $t('user.'+error) }}
                </div>
              </div>
            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </section>
</template>

<script>
import filters from "@/mixins/filters";
import { required, minLength } from "vuelidate/lib/validators";
import { isUndefined } from "underscore";
export default {
  data() {
    return {
      login: "",
      password: "",
      error: null,
      isLoading: false,
      showPass: false,
      isSubmited: false
    }
  },
  mixins: [filters],
  validations() {
    return {
      login: { required, minLength: minLength(3) },
      password: { required, minLength: minLength(3) }
    }
  },
  methods: {
    signIn: function () {
      let login = this.login
      let password = this.password
      this.isSubmited = true;
      if(!this.$v.$invalid) {
        this.isLoading = true;
        this.$store.dispatch('auth/signIn', { login, password })
          .then((data) => {

            this.isLoading = false;
            this.error = null;
            // if(typeof data.data.can_be_verified === 'undefined') {
            //   this.$store.dispatch('auth/getMe').then(() => {
            //     this.$store.dispatch('contacts/setLogin');
            //     this.$store.dispatch('contacts/initSearchList');
            //     this.$store.dispatch('contacts/changeLoading', false);
            //   })
            //   this.$router.push({ name: 'Home' }).then(() => {
            //     window.reloadHome = true;
            //   })
            // } else {
              if(isUndefined(data.data.secret)) {
                this.$router.push({name: 'verification'})
              } else {
                this.$router.push({name: 'VerificationAdd'})
              }
            // }
          })
          .catch(err => {
            this.isLoading = false;
            this.error = err.response.data.message ? err.response.data.message.replace(/\./, '') : err.response.data.error
          })
      }
    }
  },
  mounted() {
    $(document).trigger('ready');
    if (localStorage.getItem('currentLanguage')) {
      this.$root.$i18n.locale = localStorage.getItem('currentLanguage');
    } else {
      if (/^uk\b/.test(navigator.language)) {
        this.$root.$i18n.locale = 'ua';
      } else if (/^ru\b/.test(navigator.language)) {
        this.$root.$i18n.locale = 'ru';
      } else {
        this.$root.$i18n.locale = 'en';
      }
      localStorage.setItem('currentLanguage', this.$root.$i18n.locale);
    }
    if(localStorage.getItem('token')) {
      this.$store.dispatch('auth/logoutAction')
    }
    // this.$root.$i18n.locale = 'ru'
    // window.login = true;
  }
}
</script>

<style scoped>
form {
  padding: 10px 20px;
}
</style>
<style>
.pcoded.loginPage #styleSelector {
  display: none;
}

.show-password {
  position: absolute;
  top: 50px;
  right: 10px;
  cursor: pointer;
}

.show-password:hover {
  opacity: .7;
}
</style>
<style>

  .row>.login-form-wrapper {
    max-width: 600px;
    width: 100%;
  }
</style>